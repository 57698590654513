// 接口默认数据
export const defaultData = {
  // 接口版本号
  apiVersions: "v1",
  // app版本号
  appVersion: "0.0.0",
  // 设备唯一标识
  deviceCode: "developer",
  // 平台标识
  platform: 0,
  // 当前登录sessionKey，登录时由接口返回，如果没有，则留空
  sessionKey: getCookie('sessionKey'),
  // 10位时间戳
  timestamp: Math.floor(new Date().getTime() / 1000),
  // MD5加密串
  token: "7aeeb7da08390a43f73f97e3bc319c79",
  source: 1
};

// 请求设置
export const axiosConfig = {
  //    baseURL: 'http://test.gzlingren.com:10081',
  baseURL: "",

  // 请求超时时长
  timeout: 5 * 60 * 1000,

  // 请求头
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export function getCookie(key) {
	var arr, reg = new RegExp("(^| )" + key + "=([^;]*)(;|$)");
	arr = document.cookie.match(reg);
	if (arr) {
		return arr[2];
	} else {
		return null;
	}
};