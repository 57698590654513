import store from "@/store";

function checkPermission(el, binding) {
  const { value } = binding;
  const permissionList = store.state.system.permissionCodes;

  if (value && value instanceof Array) {
    if (value.length > 0) {
      const permissionRoles = value;

      const hasPermission = permissionList.some((role) => {
        return permissionRoles.includes(role);
      });

      if (!hasPermission) {
         el.parentNode && el.parentNode.removeChild(el);
      }
    }
  } else {
    throw new Error(`need permission! Like v-permission="['admin','editor']"`);
  }
}

export default checkPermission;
