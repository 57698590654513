import axios from "axios";
import { defaultData, axiosConfig, getCookie } from "./config.js";
import { message, Modal } from "ant-design-vue";
import store from "@/store/index";
import { initRouter } from "@/utils/routerUtil";

const api = axios.create(axiosConfig);

// 添加请求拦截器
api.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
api.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    if (response.data.code === 4003) {
            // 接口返回登录失效码时
           Modal.info({
            title: '温馨提示',
            content: '登录失效，请重新登录',
            onOk() {
                // 清除vuex中的用户登录信息
                store.dispatch("system/logout");
                // 重置路由
                initRouter();
                window.location.reload();
            }
          })
          return Promise.reject(response.data);
        }
    	if (response.data.code === 4002) {
    	    // 接口返回登录失效码时
    	   Modal.info({
    	    title: '温馨提示',
    	    content: '被挤下线了',
    	    onOk() {
    	        // 清除vuex中的用户登录信息
    	        store.dispatch("system/logout");
    	        // 重置路由
    	        initRouter();
    	        window.location.reload();
    	    }
    	  })
    	  return Promise.reject(response.data);
    	}
    if (response.data.code !== 200) {
      message.error(response.data.message);
    }
    return Promise.resolve(response.data);
  },
  //接口错误状态处理
  (error) => {
    // 返回接口返回的错误信息
    store.commit("system/setLoading", false);
    message.error("接口请求错误，请联系管理员");
    return Promise.reject(error.response);
  }
);

window.axiosLocks = {};

export async function send(opts) {
  // 根据请求接口method + url + data 判断是否以相同入参重复请求了相同接口；
  let key = opts.method + opts.url + JSON.stringify(opts.data);
  if (window.axiosLocks[key]) {
    // 若当前接口正在以相同参数请求中，则返回错误
    return new Promise(async (resolve, reject) => {
        await window.axiosLocks[key];
        reject({ code: -1, message: "接口重复请求，请稍后再试~" });
    })
  } else {
	defaultData.sessionKey = getCookie("sessionKey");
    defaultData.data = JSON.stringify(opts.data);
    let postData = "";
    for (let it in defaultData) {
      postData +=
        encodeURIComponent(it) +
        "=" +
        encodeURIComponent(defaultData[it]) +
        "&";
    }
    opts.data = postData;
    let promise = new Promise((resolve, reject) => {
      api(opts)
        .then((resp) => {
          // 响应成功后，删除接口重复请求判断
          delete window.axiosLocks[key];
          resolve(resp);
        })
        .catch((err) => {
          // 响应成功后，删除接口重复请求判断
          delete window.axiosLocks[key];
          reject(err);
        });
    });
    window.axiosLocks[key] = promise;
    return promise;
  }
}

export async function upload(opts) {
  // 根据请求接口method + url + data 判断是否以相同入参重复请求了相同接口；
  let key = opts.method + opts.url + JSON.stringify(opts.data);
  if (window.axiosLocks[key]) {
    // 若当前接口正在以相同参数请求中，则返回错误
     return new Promise(async (resolve, reject) => {
       await window.axiosLocks[key];
       reject({ code: -1, message: "接口重复请求，请稍后再试~" });
     })
  } else {
    defaultData.sessionKey = getCookie("sessionKey");
	let formData = new FormData();
    for (let it in defaultData) {
		formData.append(encodeURIComponent(it), encodeURIComponent(defaultData[it]));
    }
	if (formData.has('data')) {
		formData.set('data', JSON.stringify(opts.data));
	} else {
		formData.append('data', JSON.stringify(opts.data));
	}
	formData.append('file', opts.file.originFileObj);
	opts.transformrequest = [function(data, headers) {
      // 去除post请求默认的content-type
      delete headers.post['Content-Type'];
	  delete headers.post['content-type'];
      return data;
    }];
	opts.data = formData;
    let promise = new Promise((resolve, reject) => {
      api(opts)
        .then((resp) => {
          // 响应成功后，删除接口重复请求判断
          delete window.axiosLocks[key];
          resolve(resp);
        })
        .catch((err) => {
          // 响应成功后，删除接口重复请求判断
          delete window.axiosLocks[key];
          reject(err);
        });
    });
    window.axiosLocks[key] = promise;
    return promise;
  }
}

export default api;

