// see https://stackoverflow.com/questions/66140411/you-are-running-the-esm-bundler-build-of-vue-i18n-it-is-recommended-to-configur;
import { createI18n } from "vue-i18n/index";
// antd语言包
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";

// 系统语言包
import enLocale from "./en";
import cnLocale from "./zh";

import store from "@/store/index";

export const messages = {
  en: {
    ...enUS,
    ...enLocale,
  },
  zh: {
    ...zhCN,
    ...cnLocale,
  },
};

export function getLanguage() {
  const chooseLanguage = store.language;
  if (chooseLanguage) return chooseLanguage;

  // if has not choose language
  const language = (
    navigator.language || navigator.browserLanguage
  ).toLowerCase();
  const locales = Object.keys(messages);
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }
  return "zh";
}

const i18n = createI18n({
  locale: getLanguage(),
  globalInjection: true,
  messages,
  silentTranslationWarn: true,
});

export default i18n;
