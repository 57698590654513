
const myMixin = {
	data() {
		return {
			// projectSetting: {
			// 	name: "烽禾影城",
			// 	logo: require("@/assets/image/logo.png"),
			// },
			isMock: true,
			currentTime: 0,
			moment: require('moment')
		};
	},
	methods: {
		transDateTime(val, type) {
			if (!val) return "-";
			if ((val + "").length < 13) val = val * 1000;
			if (type === 1) {
				return this.moment(val).format("YYYY-MM-DD");
			}
			return this.moment(val).format("YYYY-MM-DD HH:mm:ss");
		},
		transTime(val) {
			if (!val) return "-";
			if ((val + "").length < 13) val = val * 1000;
			return this.moment(val).format("YYYY-MM-DD");
		},
		//obj对象转为json格式的字符串
		j2s: function(obj) {
			return JSON.stringify(obj);
		},
		// 获取缓存信息
		$getLS: function(key, isSession) {
			var storage = isSession ? window.sessionStorage : window.localStorage;
			if (storage) {
				var v = storage.getItem(key);
				if (!v) return;
				if (v.indexOf("obj-") === 0) {
					return JSON.parse(v.slice(4));
				} else if (v.indexOf("str-") === 0) {
					return v.slice(4);
				}
			}
		},
		// 设置缓存信息
		$setLS: function(key, value, isSession) {
			if (arguments.length >= 2) {
				var storage = isSession ? window.sessionStorage : window.localStorage;
				var v =
					typeof value === "object" ?
					"obj-" + JSON.stringify(value) :
					"str-" + value;
				storage && storage.setItem(key, v);
			}
		},
		// 移除缓存信息
		$rmLS: function(key, isSession) {
			var storage = isSession ? window.sessionStorage : window.localStorage;
			storage && key && storage.removeItem(key);
		},
		// 清空缓存信息
		$clearLS: function(isSession) {
			var storage = isSession ? window.sessionStorage : window.localStorage;
			storage && storage.clear();
		},
		// 对象克隆
		clone: function(obj) {
			var _g = this;
			var o;
			if (typeof obj == "object") {
				if (obj === null) {
					o = null;
				} else {
					if (obj instanceof Array) {
						o = [];
						for (var i = 0, len = obj.length; i < len; i++) {
							o.push(_g.clone(obj[i]));
						}
					} else {
						o = {};
						for (var j in obj) {
							o[j] = _g.clone(obj[j]);
						}
					}
				}
			} else {
				o = obj;
			}
			return o;
		},
		// 移除当前路由视图
		$removeCurrentPage() {
			this.$store.commit(
				"system/delCachedViews",
				this.$router.currentRoute.value
			);
		},
		// 关闭指定路由并跳转至指定路由
		$closePage(closeRoute, nextRoute) {
			if (typeof closeRoute === "string") {
				closeRoute = {
					path: closeRoute,
				};
			}
			this.$store.commit("system/delCachedViews", closeRoute);
			this.$nextTick(() => {
				if (nextRoute) {
					if (typeof nextRoute === "string") {
						nextRoute = {
							path: nextRoute,
						};
					}
					const index = this.$store.state.system.cachedViews.indexOf(
						nextRoute.path
					);
					if (index !== -1) {
						if (!nextRoute.query) {
							nextRoute.query =
								this.$store.state.system.visitedViews[index].query;
						}
						this.$store.commit("system/delCachedViews", nextRoute);
					}
					this.$nextTick(() => {
						this.$router.replace(nextRoute);
					});
				} else {
					this.$router.go(-1);
				}
			});
		},
		$deepClone(obj) {
			const mapType = '[object Map]';
			const setType = '[object Set]';
			const arrayType = '[object Array]';
			const objectType = '[object Object]';
			const deepmap = [mapType, setType, arrayType, objectType]
			
			const boolType = '[object Boolean]';
			const dateType = '[object Date]';
			const numberType = '[object Number]';
			const stringType = '[object String]';
			const symbolType = '[object Symbol]';
			const errorType = '[object Error]';
			const regexpType = '[object RegExp]';
			const funcType = '[object Function]';
			
			//先考虑是否是对象
			function isObject(target) {
			    const type = typeof target;
			    return target != null && (type === 'object' || type === 'function')
			}
			
			function getType(target) {
			    return Object.prototype.toString.call(target)
			}
			function getInit(target) {
			    return new target.constructor()
			}
			function cloneSymbol(targe) {
			    return Object(Symbol.prototype.valueOf.call(targe));
			}
			function cloneRegExp(regexp) {
			    const result = new RegExp(regexp.source, reFlags.exec(regexp))
			    result.lastIndex = regexp.lastIndex
			    return result
			}
			
			function cloneOtherType(targe, type) {
			    const Ctor = targe.constructor;
			    switch (type) {
			        case boolType:
			        case numberType:
			        case stringType:
			        case errorType:
			        case dateType:
			            return new Ctor(targe);
			        case regexpType:
			            return cloneRegExp(targe);
			        case symbolType:
			            return cloneSymbol(targe);
			        case funcType:
			            return target;
			        default:
			            return null;
			    }
			}
			function clonedeep(target, map = new WeakMap()) {
			    //处理基本数据类型
			    if (!isObject(target)) {
			        return target
			    }
			    //处理引用类型
			    else {
					if (target._isAMomentObject) {
						//  moment对象直接返回
						return target;
					}
			        if (map.get(target)) {
			            return map.get(target)
			        }
					let result;
			        map.set(target, result)
			        const type = getType(target)
			        //处理可继续遍历对象
			        if (deepmap.includes(type)) {
			            result = getInit(target)
			            // 处理 Set
			            if (type === setType) {
			                target.forEach(value => {
			                    result.add(deepclone(value, map));
			                });
			                return result;
			            }
			            // 处理 Map
			            if (type === mapType) {
			                target.forEach((value, key) => {
			                    result.set(key, deepclone(value, map));
			                });
			                return result;
			            }
			            //处理对象或者数组
			            for (const key in target) {
			                result[key] = clonedeep(target[key], map);
			            }
			            return result;
			        } else {
			            cloneOtherType(targe, type)
			        }
			    }
			}
			
			return clonedeep(obj);
		}
	},
};

export default myMixin;
