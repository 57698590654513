<template>
	<a-layout class="ui-layout">
		<a-layout-sider :width="250" class="ui-transition ui-left__sider" v-model:collapsed="collapsed">
			<div class="ui-logo" @click="onGotoIndex">
				<img :src="$store.state.system.projectSetting.logo" class="ui-logo__img" />
				<span class="ui-logo__title" v-show="!collapsed">{{ $store.state.system.projectSetting.name }}</span>
			</div>
			<side :selectedKeys="selectedKeys"></side>
		</a-layout-sider>
		<a-layout style="position: relative;padding-left: 20px;background-color: #FFF;;">
			<a-layout-header class="ui-transition ui-header" :style="{
				  position: 'absolute',
				  left: '20px',
				  right: 0,
				  zIndex: 1,
				}"
			>
				<myHeader :selectedKeys="selectedKeys" :openRoutes="openRoutes"></myHeader>
				<div id="headerTag" class="ui-tag" v-if="visitedViews.length !== 0">
					<!-- <a-tag
						class="ui-tag__item"
						:class="
						  $router.currentRoute.value.path === item.path && 'is-action'
						"
						v-for="(item, index) in visitedViews"
						:key="item.path"
						closable
						@close="onTagClose(item, index)"
						@click="onTagChange(item)"
					  >
						{{ $te(item.meta.title) ? $t(item.meta.title) : item.meta.title }}
					  </a-tag> -->
					<a-tabs style="width: 100%;" v-model:activeKey="currentPath" type="editable-card" hide-add
						@edit="onEdit" @change="onTabChange">
						<a-tab-pane :key="item.path" v-for="item in visitedViews" closable
							:tab="$te(item.meta.title) ? $t(item.meta.title) : item.meta.title "></a-tab-pane>
							
							<template #rightExtra>
								<a-dropdown :trigger="['click', 'hover']">
									<span style="padding: 8px 10px;font-size: 16px;border-radius: 4px;border: solid 1px #eee;;">
										<Icon icon="DownOutlined"></Icon>
									</span>
									<template #overlay>
										<a-menu>
											<a-menu-item @click="onClose(1)" style="color: rgba(0,0,0,.65);">
												<Icon icon="ArrowLeftOutlined"></Icon>
												<span style="padding-left: 6px;">关闭左侧</span>
											</a-menu-item>
											<a-menu-item @click="onClose(2)" style="color: rgba(0,0,0,.65);">
												<Icon icon="ArrowRightOutlined"></Icon>
												<span style="padding-left: 6px;">关闭右侧</span>
											</a-menu-item>
											<a-menu-item @click="onClose(3)" style="color: rgba(0,0,0,.65);">
												<Icon icon="CloseOutlined"></Icon>
												<span style="padding-left: 6px;">关闭其它</span>
											</a-menu-item>
										</a-menu>
									</template>
								</a-dropdown>
							</template>
					</a-tabs>
				</div>
			</a-layout-header>
			<a-layout-content class="ui-main">
				<appMain></appMain>
			</a-layout-content>
			<!-- <a-layout-footer>copyright 2022 yb </a-layout-footer> -->
		</a-layout>
	</a-layout>
</template>

<script>
	import {
		mapState
	} from "vuex";
	import { Icon } from '@/components/icon/icon.js';
	import appMain from "./appMain";
	import side from "./side";
	import myHeader from "./header";
	export default {
		components: {
			appMain,
			side,
			myHeader,
			Icon
		},
		data() {
			return {
				openRoutes: [], // 面包屑
				selectedKeys: [], // 左侧导航栏选择
				currentPath: ''
			};
		},
		computed: {
			...mapState("system", ["collapsed"]),
			cachedViews() {
				return this.$store.state.system.cachedViews;
			},
			visitedViews() {
				return this.$store.state.system.visitedViews;
			},
		},
		watch: {
			$route: {
				handler(newVal) {
					if (newVal.path.indexOf("/login") !== -1) return;
					if (newVal.path.indexOf("/403") !== -1) return;
					if (newVal.matched && newVal.matched.length) {
						this.openRoutes = newVal.meta.breadcrumbNeste;
						this.selectedKeys = newVal.meta.breadcrumbNeste.map((item) => {
							return item.path;
						});
					} else {
						this.openRoutes = [];
						this.selectedKeys = [newVal.name];
					}
					this.currentPath = this.$router.currentRoute.value.path;
				},
				immediate: true,
			},
		},
		created() {
			this.currentPath = this.$router.currentRoute.value.path;
		},
		methods: {
			onGotoIndex() {
				this.$router.replace("/");
			},
			onTagClose(item) {
				this.$store.commit("system/delCachedViews", item);
				if (item.path === this.$router.currentRoute.value.path) {
					if (this.visitedViews.length) {
						this.$router.replace({
							path: this.visitedViews[this.visitedViews.length - 1].path,
							query: this.visitedViews[this.visitedViews.length - 1].query,
						});
					} else {
						this.$router.replace("/");
					}
				}
			},
			onTabChange(e) {
				const item = this.visitedViews.find(tab => {
					return tab.path == e
				});
				this.onTagChange(item);
			},
			onClose(type) {
				let length = this.visitedViews.length - 1 || 0;
				let index = -1;
				for (let i = length; i >= 0; i--) {
					let item = this.visitedViews[i];
					if (item.path === this.currentPath) {
						index = i;
					} else {
						if (index !== i) {
							if ((index < i && type === 2) || type === 3) {
								this.$store.commit("system/delCachedViews", item);
							}
							if (index > i && type === 1) {
								this.$store.commit("system/delCachedViews", item);
							}
						}
					}
				}
			},
			onBreadClick(routes, index) {
				if (index === this.openRoutes.length - 1) return;
				this.$router.replace({
					path: routes.path
				});
			},
			onEdit(e) {
				const item = this.visitedViews.find(tab => {
					return tab.path == e
				});
				this.onTagClose(item);
			},
			onTagChange(item) {
				if (this.$router.currentRoute.value.path === item.path) return;
				this.$router.push({
					path: item.path,
					query: item.query,
				});
			},
		},
	};
</script>

<style lang="less" scoped>
	.ui-menu {
		height: calc(100vh - 52px);
		overflow-y: scroll;
	}

	.ui-menu::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}

	.ui-menu::-webkit-scrollbar-thumb {
		background-color: #d1e9ff;
		border-radius: 10px;
	}

	.ui-layout {
		min-height: 100vh;
		min-width: 1200px;
		background-color: #FFF;
		position: relative;
	}

	.ui-transition {
		transition: all 0.15s linear;
	}

	.ui-left__sider {
		border-top-right-radius: 40px;
		border-bottom-right-radius: 40px;
		background-image: url(../assets/image/backstage_img_left.png);
		background-size: 100% 100%;
	}

	.ui-header {
		right: auto;
		border-bottom-left-radius: 30px;
		background-color: #FFF;
		box-shadow: 0px 1px 5px #eee;
	}

	.ui-bread {
		padding-top: 10px;
		padding-bottom: 10px;
		text-align: left;
		background-color: #FFFFFF;

		span {
			cursor: pointer;
		}
	}

	.ui-main {
		height: calc(100vh - 330px);
		padding: 10px 20px 20px 0;
		margin-top: 146px;
		overflow: auto;
		background-color: #ffffff;
		text-align: left;
	}

	.ui-tag {
		display: flex;
		overflow-x: auto;
		overflow-y: hidden;
		padding: 2px 0 0px 10px;
		margin: 0px 0px 0 -30px;
		line-height: 0px;
		text-align: left;
		background-color: #FFF;
		// background-color: #f4f5f7;
		// box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);

		.ui-tag__item {
			margin-right: 5px;
			padding: 2px 7px;
			cursor: pointer;
		}

		.is-action {
			color: #1890ff;
			background-color: #e8f4ff;
			border-color: #d1e9ff;
		}
	}

	.ui-tag::-webkit-scrollbar {
		width: 10px;
		height: 5px;
	}

	.ui-tag::-webkit-scrollbar-thumb {
		background-color: #d1e9ff;
		border-radius: 10px;
	}

	.ui-logo {
		display: flex;
		padding: 10px 17px 2px;
		cursor: pointer;
	}

	.ui-logo__img {
		width: 40px;
		height: 40px;
	}

	.ui-logo__title {
		margin: auto 8px;
		font-size: 20px;
		font-weight: 600;
		color: #ffffff;
	}
</style>
<style>
	.ui-layout .ant-layout-header {
		height: 50px;
		padding: 0 20px;
		line-height: 50px;
	}

	.ui-layout .ant-layout-footer {
		padding: 14px;
	}

	#headerTag .ant-tabs-top>.ant-tabs-nav {
		margin-bottom: 0;
	}
</style>
