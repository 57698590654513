<template>
  <div>
    <a-spin :spinning="$store.state.system.loading" tip="加载中...">
      <router-view v-slot="{ Component }">
        <!-- v-if 处设置 Component.type.name 的值为当前路由, 用于缓存 -->
        <keep-alive
          v-if="(Component.type.name = key)"
          :max="15"
          :include="cachedViews"
        >
          <component :is="Component" :key="key"></component>
        </keep-alive>
      </router-view>
    </a-spin>
  </div>
</template>

<script>
export default {
  name: "AppMain",
  setup() {},
  computed: {
    cachedViews() {
      return this.$store.state.system.cachedViews.join(",") || "";
    },
    key() {
      return this.$router.currentRoute.value.path;
    },
  },
};
</script>
