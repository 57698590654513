let uiVersion = getStorage('uiVersion') || 1;

const state = {
	uiVersion: uiVersion
}

const mutations = {
	setUiVersion(state, value) {
		state.uiVersion = value;
		setStorage('uiVersion', value);
	}
}

export default {
  namespaced: true,
  state,
  mutations
};

function setStorage(key, value, isSession) {
  let obj = {
    obj: value,
  };
  if (isSession) {
    window.sessionStorage.setItem(key, JSON.stringify(obj));
  } else {
    window.localStorage.setItem(key, JSON.stringify(obj));
  }
}

function getStorage(key, isSession) {
  if (isSession) {
    if (window.sessionStorage.getItem(key)) {
      return JSON.parse(window.sessionStorage.getItem(key)).obj;
    } else {
      return undefined;
    }
  } else {
    if (window.localStorage.getItem(key)) {
      return JSON.parse(window.localStorage.getItem(key)).obj;
    } else {
      return undefined;
    }
  }
}
