<template>
  <div>
    <a-menu
		id="leftMenu"
      :openKeys="openKeys"
      :selectedKeys="selectedKeys"
      mode="inline"
      theme="dark"
      @openChange="openChange"
    >
      <sideItem
        v-for="item in leftSideRoute"
        :key="item.parentPath"
        :itemData="item"
      ></sideItem>
    </a-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    selectedKeys: Array,
  },
  data() {
    return {
      openKeys: [],
    };
  },
  watch: {
    selectedKeys: {
      handler(newVal) {
        newVal.forEach((key) => {
          if (this.openKeys.indexOf(key) === -1) {
            this.openKeys.push(key);
          }
        });
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState("system", ["leftSideRoute"]),
  },
  methods: {
    handleClick(e) {
      this.$router.push({ name: e.key });
    },
    openChange(keys) {
      this.openKeys = keys;
    },
  },
};
</script>

<style>
	#leftMenu {
		width: 100%;
		background-color: TRANSPARENT;
	}
	#leftMenu .ant-menu-inline.ant-menu-sub {
		background: TRANSPARENT;
	}
</style>
